<template>
  <member-invite-flow />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MemberInviteFlow from '@/pages/responsive-pages/invite-authenticated/member-invite/MemberInviteFlow';
import Segment from '@/Segment';

export default {
  name: 'InviteAuthenticateToken',
  components: {
    MemberInviteFlow,
  },
  async created() {
    this.startLoading(this.$options.name);
    const token = this.$route.query.inviteToken || this.$cookies.get('inviteToken');
    if (!token) {
      this.$router.push({ path: '/sr' });
    } else {
      this.$cookies.set('inviteToken', token, '1h');
      await this.$store.dispatch('inviteSession/init', token);
      if (!this.inviteToken) {
        this.$router.push({ path: '/sr' });
      } else {
        this.$router.replace({ query: {} });
      }
    }
    Segment.reload();
    this.doneLoading(this.$options.name);
  },
  computed: {
    ...mapState('inviteSession', ['inviteToken']),
  },
  methods: {
    ...mapActions('loader', ['startLoading', 'doneLoading']),
  },
};
</script>
