<template>
  <login-support-footer class="bottom"/>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'LoginSupportFooterPositioned',
  components: {
    LoginSupportFooter: BaseUI.LoginSupportFooter,
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  padding-bottom: 30px;
  margin-top: auto;
}
</style>
