<template>
  <div v-if="customerCreateFailed">
    <server-error/>
  </div>
  <div class="signup" v-else>
    <slot name="header"/>
    <div class="intake-container">
      <div class="title">{{ title }}</div>
      <div class="subTitle">{{ subTitle }}</div>
    </div>
    <slot name="information-details"/>
    <div class="button-container">
      <riseup-button :disabled="false" :title="buttonTitle" :size="'small'" :action="buttonAction"/>
    </div>
    <support-footer/>
  </div>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import { mapState } from 'vuex';
import ServerError from '../pages/general-errors/ServerError';

export default {
  name: 'SignupForMember',
  components: {
    SupportFooter: BaseUI.SupportFooter,
    RiseupButton: BaseUI.RiseupButton,
    ServerError,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    buttonAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapState('signup', ['serverErrors']),
    customerCreateFailed() {
      return this.serverErrors.createCustomer;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';

.signup {
  @include white-page-frame;

  .intake-container {
    color: $riseup_black;
    @include responsive-padding;
    margin-top: 30px;

    .title {
      font-family: SimplerPro;
      font-weight: bold;
      font-size: 22px;
      line-height: 1.4;
      display: inline-block;
      width: 100%;
    }

    .subTitle {
      font-size: 22px;
      color: $riseup_black;
      font-weight: bold;
      margin-bottom: 32px;
    }

    .action {
      display: flex;
      justify-content: center;
      margin: 40px 0px;
      color: $riseup_black;
      font-size: 18px;
      line-height: 10px;
      .action-link {
        font-weight: bold;
        color: $riseup_blue;
        cursor: pointer;
        text-decoration: underline;
        margin-right: 5px;
      }
    }
  }
  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 31px;
    .proceed-button {
      width: 85%;
    }
  }
  .support-footer {
    text-align: center;
    padding: 24px 0px;

    .support {
      color: $riseup_black
    }
  }
}

</style>
