<template>
  <two-factor-page-base>
    <div class="add-2fa-done-screen">
      <div class="ri-body margin-bottom-xxs">כניסה</div>
      <div class="ri-large-headline ri-alte margin-bottom-xxs">נהדר, אמצעי ההזדהות הנוסף שלך נשמר בהצלחה!</div>
      <div class="ri-large-body">מעכשיו,
        <span v-if="auth0Method===google">בכניסה לשירות נבקש ממך להזדהות עם חשבון Google</span>
        <span v-else-if="auth0Method===facebook">בכניסה לשירות נבקש ממך להזדהות עם חשבון Facebook</span>
        <span v-else> בתהליך ההזדהות נבקש ממך להזין גם את הסיסמא האישית</span>
        <span class="bold">בנוסף לקוד האימות</span>
        החד פעמי.
      </div>
      <riseup-button class="margin-top-xl" @click="next" title="הבנתי, אפשר להמשיך"/>
    </div>
  </two-factor-page-base>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import DDLogs from '@/DDLogs';
import CustomersApi from '../../../../api/CustomersApi';
import Segment from '../../../../Segment';
import TwoFactorPageBase from './TwoFactorPageBase';

export default {
  name: '2faLoginDone',
  components: {
    TwoFactorPageBase,
    RiseupButton: BaseUI.V2Components.RiseupButton,
  },
  data() {
    return {
      currentMember: {},
      auth0Method: null,
    };
  },
  async created() {
    this.google = 'google';
    this.refreshed = this.$route.query.refreshed;
    if (!this.refreshed) {
      this.auth0Method = await CustomersApi.assignAuth0Id();
    }
  },
  methods: {
    next() {
      Segment.trackUserInteraction('2faGoToCashflowClicked');
      DDLogs.log('Customer redirect to cash flow after 2fa login done');
      window.location.href = `/${this.$route.query.redirectTo || 'sr'}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../scss/mixins';

.add-2fa-done-screen {

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 30px;
  .bold {
    font-weight: bold;
  }
}
</style>
