<script>
import { mapActions, mapMutations } from 'vuex';
import Segment from '@/Segment';

export default {
  name: 'GenerateCodeMixin',
  methods: {
    ...mapActions('signup', ['generateCode']),
    ...mapMutations('signup', ['setFirstName', 'setLastName', 'setPhoneNumber', 'setEmail', 'setEnableMarketingEmails']),
    async generateCodeForSignup(firstName, lastName, phoneNumber, email, enableMarketingEmails) {
      await this.setFirstName(firstName);
      await this.setLastName(lastName);
      await this.setPhoneNumber(phoneNumber);
      await this.setEmail(email);
      await this.setEnableMarketingEmails(enableMarketingEmails);
      Segment.trackUserInteraction('GenerateCodeClicked_Marketing');
      Segment.trackUserInteraction('GenerateCodeClicked', {
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        isMarketingConsent: this.enableMarketingEmails,
      });
      await this.generateCode();
    },
  },
};
</script>
