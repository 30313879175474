<template>
  <signup :title="title"
          :sub-title="'ליצירת החשבון נצטרך כמה פרטים:'"
          :button-title="'אני רוצה להירשם'"
          :button-action="generateCodeAndProceed"
          :error="serverErrors.generateCode"
          :reset-error="() => resetError('generateCode')">
    <template v-slot:header>
      <img class="promotion-header" :src="require(`@/assets/${promotionDetails.header}`)"/>
    </template>
    <template v-slot:information-details>
      <information-details :title="promotionDetails.detailsTitle"
                           :details="detailsText"/>
    </template>
  </signup>
</template>

<script>

import { mapState, mapMutations } from 'vuex';
import Signup from '@/base-components/Signup';
import InformationDetails
  from '@/pages/responsive-pages/non-authenticated/wine-and-dine/signup/steps/InformationDetails';
import GenerateCodeMixin from '@/pages/responsive-pages/non-authenticated/wine-and-dine/signup/steps/GenerateCodeMixin';
import Segment from '../../../../../../Segment';
import promotionConsts from '../../../../../../constants/promotions';

export default {
  name: 'IntroductionWithPromo',
  mixins: [GenerateCodeMixin],
  components: {
    InformationDetails,
    Signup,
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      firstPayingMonth: { 1: 'השלישי', 2: 'הרביעי', 3: 'החמישי', 4: 'השישי', 5: 'השביעי', 11: 'ה-13' },
    };
  },
  async created() {
    Segment.trackUserGot('RegistrationEntered', { promotion: this.promotion });
    Segment.trackUserGotToPixel('RE');
  },
  computed: {
    ...mapState('signup', ['phoneNumber', 'firstName', 'lastName', 'serverErrors', 'email', 'enableMarketingEmails']),
    ...mapState('promotions', ['promotion', 'benefitAmount']),
    promotionDetails() {
      return promotionConsts.getPromotionDetails(this.promotion);
    },
    detailsText() {
      const mothsText = this.benefitAmount === 1 ? 'חודש נוסף במנוי במתנה' : `${this.benefitAmount} חודשים נוספים במנוי במתנה `;
      return promotionConsts.getPromotionDetails(this.promotion).details(mothsText, this.firstPayingMonth[this.benefitAmount]);
    },
    title() {
      const mothsText = this.benefitAmount === 1 ? 'חודשיים' : ` ${this.benefitAmount + 1} חודשים `;
      return promotionConsts.getPromotionDetails(this.promotion).title(mothsText);
    },
  },
  methods: {
    ...mapMutations('signup', ['resetError']),
    async generateCodeAndProceed(firstName, lastName, phoneNumber, email, enableMarketingEmails) {
      await this.generateCodeForSignup(firstName, lastName, phoneNumber, email, enableMarketingEmails);
      this.next();
    },
    goToLogin() {
      window.location.href = '/sr';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../../scss/mixins';
.promotion-header {
  width: 100%;
}

</style>
