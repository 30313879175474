<template>
  <two-factor-page-base>
    <div class="google-login">
      <div class="content">
        <div class="ri-body margin-bottom-xxs">כניסה</div>
        <div class="ri-large-headline ri-alte margin-bottom-xxs">בשביל להגיע ללינק שרצית, צריך לפתוח אותו בדפדפן אחר</div>
        <span class="ri-large-body">רק צריך להעתיק את הלינק ולהדביק בכל דפדפן אחר מחוץ לאפליקציית פייסבוק</span>
        <riseup-button
          class="margin-top-ml"
          title="להעתיק את הלינק"
          @click="copyToClipboard"
        />
      </div>
      <div class="support-footer">
        <div class="support">לא הצלחת לעבור לדפדפן אחר?</div>
        <a class="support-link" href="mailto:support@riseup.co.il?subject=יש לי שאלה" target="_blank">לעזרה מהצוות</a>
      </div>
    </div>
  </two-factor-page-base>
</template>

<script>

import DDLogs from '@/DDLogs';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import TextCopiedToaster from '../../authenticated/navigation-pages/cashflow-app/toasters/TextCopiedToaster';
import TwoFactorPageBase from './TwoFactorPageBase';

export default {
  name: 'LoginWithGoogleInFacebookBrowser',
  components: {
    RiseupButton: BaseUI.V2Components.RiseupButton,
    TwoFactorPageBase,
  },
  data() {
    return {
      currentMember: {},
      auth0Method: null,
      url: null,
    };
  },
  async created() {
    Segment.trackUserGot('GotToDeadEnd');
    const { redirectTo, ...query } = this.$route.query;
    DDLogs.log(`The customer login with google on facebookup -> ${redirectTo}`);
    this.google = 'google';
    const queryString = new URLSearchParams(query).toString();
    const querySuffix = queryString ? `?${queryString}` : '';
    this.url = `https://input.riseup.co.il/${redirectTo || ''}${querySuffix}`;
    if (!this.isFacebookApp) {
      Segment.trackUserGot('GotLoginWithGoogleOnFacebookAppAndOpenNewBrowser', { redirectTo });
      DDLogs.log(`The customer login with google on facebook app and open new browser ${redirectTo}`);
      window.location.href = `/${redirectTo || 'sr'}${querySuffix}`;
    } else {
      Segment.trackUserGot('GotLoginWithGoogleOnFacebookApp', { redirectTo });
      DDLogs.log('The customer login with google on facebook app');
    }
  },
  computed: {
    userAgent() {
      return navigator.userAgent || navigator.vendor || window.opera;
    },
    isFacebookApp() {
      return (this.userAgent.indexOf('FBAN') > -1) || (this.userAgent.indexOf('FBAV') > -1);
    },
  },
  methods: {
    next() {
    },
    copyToClipboard() {
      this.$copyText(this.url).then(e => {
        EventBus.$emit('open-toaster', {
          component: TextCopiedToaster,
          props: {
          },
        });
        Segment.trackUserGot('GotLoginWithGoogleOnFacebookAppAndCopyLink');
        DDLogs.log('The customer login with google on facebook app and copied the link');
      }, e => {
        Segment.trackUserGot('GotLoginWithGoogleOnFacebookAppFailedCopyLink');
        DDLogs.log('The customer login with google on facebook app and failed copied the link');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../scss/mixins';

.google-login {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .header {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }

  .image {
    width: 100%;
  }

  .content {
    padding: 50px 30px 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .support-footer {
    font-family: SimplerPro;
    font-size: 16px;
    line-height: 22px;
    color: $riseup_gray_2;
    width: 100%;
    bottom: 0;
    padding: 0 30px 30px 30px;
    text-align: center;
    .separator {
      margin-bottom: 10px;
      display: inline-block;
      width: 15px;
      height: 1px;
      background-color: $riseup_gray_0;
    }
    .support-link {
      margin-top: 8px;
      color: $riseup_blue;
      cursor: pointer;
    }
  }
}

</style>
