<template>
  <alert-page :img="require('@/assets/errors/fallen-flower-pot.png')"
              img-size="small"
              title="מספר הטלפון שלך לא קיים אצלנו במערכת"
              :show-button="true"
              button-title="להרשמה"
              :button-action="goToSignup"/>
</template>

<script>
import Segment from '@/Segment';
import router from '@/router';
import AlertPage from '@/base-components/AlertPage';

export default {
  name: 'LoginMemberDoesntExistError',
  components: {
    AlertPage,
  },
  async created() {
    Segment.trackUserGot('LoginMemberDoesntExistError', { phoneNumber: this.phoneNumber });
  },
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
  },
  methods: {
    goToSignup() {
      router.push('/signup/details/v3');
    },
  },
};
</script>
