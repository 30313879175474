<template>
  <signup-for-member :title="title"
  :sub-title="'רגע לפני שנמשיך, חשוב לדעת:'"
  :button-title="'אפשר להמשיך'"
  :button-action="addCashflowForRiseupAccount"
          :error="serverErrors.createCustomer">
    <template v-slot:header>
      <riseup-header/>
    </template>
    <template v-slot:information-details>
      <default-information-details/>
    </template>
  </signup-for-member>
</template>

<script>

import SignupForMember from '@/base-components/SignupForMember';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import DefaultInformationDetails
  from './DefaultInformationDetails';
import Segment from '../../../../../../Segment';
import RiseupHeader from '../../../../../../base-components/app-header/RiseupHeader.vue';

export default {
  name: 'IntroductionForMembers',
  components: {
    RiseupHeader,
    SignupForMember,
    DefaultInformationDetails,
  },
  async created() {
    Segment.trackUserGot('RegistrationEntered');
    Segment.trackUserGotToPixel('RE');
  },
  computed: {
    ...mapState('signup', ['serverErrors', 'phoneNumber', 'firstName', 'lastName', 'serverErrors', 'email', 'enableMarketingEmails']),
    ...mapState('session', ['activeMember']),
    ...mapGetters('session', ['activeMemberFirstName']),

    title() {
      return `היי ${this.activeMemberFirstName},`;
    },
  },
  methods: {
    ...mapActions('signup', ['createCustomer']),
    ...mapMutations('signup', ['setFirstName', 'setLastName', 'setPhoneNumber', 'setEmail', 'setEnableMarketingEmails']),
    ...mapActions('session', ['init']),
    async addCashflowForRiseupAccount() {
      await this.setFirstName(this.activeMember.firstNamePiiValue);
      await this.setLastName(this.activeMember.lastNamePiiValue);
      await this.setPhoneNumber(this.activeMember.phoneNumberPiiValue);
      await this.setEmail(this.activeMember.emailPiiValue);
      await this.setEnableMarketingEmails(this.activeMember.enableMarketingEmails);
      await this.createCustomer();
      await this.init({});
      await this.$router.push({ path: '/ob' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../../scss/mixins';
.expired-promotion {
  @include responsive-padding;
  width: 100%;
}
</style>
