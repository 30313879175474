<template>
  <div class="enter-details">
    <text-input
      :autofocus="true"
      label-text="שם פרטי"
      :value="firstName"
      :onInput="setFirstName"
      :error="false"
      :topLeftLabel="''"/>

    <text-input
      :autofocus="false"
      label-text="שם משפחה"
      :value="lastName"
      :onInput="setLastName"
      :error="false"
      :topLeftLabel="''"/>

    <email-input
      label-text="אימייל"
      :value="email"
      :onInput="setEmail"
      :onError="setEmailError"/>

    <text-input
      :autofocus="false"
      label-text="טלפון נייד"
      :value="phoneNumber"
      :onInput="setPhoneNumber"
      :inputType="'number'"
      :error="!!error"
      :topLeftLabel="phoneNumberErrorLabel"/>
    <check-box :initially-checked="termsAndConditionsAgreed"
               :toggle="toggleTermsAndConditions">
    <template v-slot:title>
      <div class="title">
        קראתי ואישרתי את
        <span class="link" v-on:click="openTerms()">תנאי השימוש</span>
        <span class="link" v-on:click="openPrivacy()">ותנאי הפרטיות</span>
      </div>
    </template>
    </check-box>
    <check-box :initially-checked="enableMarketingEmails"
               :toggle="toggleEnableMarketingEmails">
      <template v-slot:title>
        <div class="title">
          אשמח לקבל עדכונים, טיפים וחדשות למייל
        </div>
      </template>
      </check-box>
    <div class="button-container">
      <riseup-button class="margin-top-xs" :disabled="disabled" :title="buttonTitle" @click="triggerAction"/>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import Segment from '../Segment';

const ERRORS = {
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  INVALID_EMAIL: 'INVALID_EMAIL',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  MEMBER_ALREADY_EXISTS: 'MEMBER_ALREADY_EXISTS',
};

export default {
  name: 'EnterDetails',
  components: {
    TextInput: BaseUI.TextInput,
    RiseupButton: BaseUI.RiseupButton,
    CheckBox: BaseUI.CheckBox,
    EmailInput: BaseUI.EmailInput,
  },
  async created() {
    Segment.trackUserGot('EnterDetailsEntered');
    if (this.predefinedDetails) {
      this.restoreDetails();
    }
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      enableMarketingEmails: true,
      termsAndConditionsAgreed: false,
      enteredBadNumber: false,
      emailError: false,
    };
  },
  props: {
    action: {
      type: Function,
      required: true,
    },
    error: {
      type: String,
      required: true,
    },
    resetError: {
      type: Function,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    predefinedDetails: {
      type: Object,
      required: false,
    },
  },
  computed: {
    disabled() {
      return (_.trim(this.firstName).length < 2
        || _.trim(this.lastName).length < 2
        || this.enteredBadNumber
        || _.isEmpty(_.trim(this.phoneNumber)))
        || this.email.length < 5
        || this.emailError
        || !this.termsAndConditionsAgreed;
    },

    phoneNumberErrorLabel() {
      if (this.error === ERRORS.INVALID_PHONE_NUMBER) {
        Segment.trackUserGot('invalidPhoneNumber', { invalidPhoneNumber: this.phoneNumber });
        this.setBadNumber();
        return 'מספר הטלפון שהזנת לא תקין';
      } if (this.error === ERRORS.MEMBER_ALREADY_EXISTS) {
        this.setBadNumber();
        return 'היי, נראה שכבר הצטרפת בעבר לשירות';
      }
      return null;
    },
    normalizedPhoneNumber() {
      return this.phoneNumber.replace(/\D/g, '');
    },
  },
  methods: {
    restoreDetails() {
      this.firstName = this.predefinedDetails.firstName;
      this.lastName = this.predefinedDetails.lastName;
      this.phoneNumber = this.predefinedDetails.phoneNumber;
      this.email = this.predefinedDetails.email;
    },
    setBadNumber() {
      this.enteredBadNumber = true;
    },
    setFirstName(value) {
      this.firstName = value;
    },
    setLastName(value) {
      this.lastName = value;
    },
    setPhoneNumber(value) {
      this.resetError();
      this.phoneNumber = value;
      this.enteredBadNumber = false;
    },
    setEmail(value) {
      this.email = value;
    },
    setEmailError(value) {
      this.emailError = value;
    },
    toggleEnableMarketingEmails() {
      this.enableMarketingEmails = !this.enableMarketingEmails;
    },
    toggleTermsAndConditions() {
      this.termsAndConditionsAgreed = !this.termsAndConditionsAgreed;
    },
    triggerAction() {
      if (!this.emailError) {
        this.action(this.firstName, this.lastName, this.normalizedPhoneNumber, this.email, this.enableMarketingEmails);
      }
    },
    openTerms() {
      window.open('https://www.riseup.co.il/termsofuse', '_blank');
    },
    openPrivacy() {
      window.open('https://www.riseup.co.il/privacy-policy', '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  .enter-details {
    .title {
      padding-top: 5px;
      font-size: 14px;
      margin-right: 20px;

      .link {
        color: $riseup_blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .action {
      margin: 36px 0px;
      color: $riseup_blue;
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
      font-size: 16px;
      text-align: center;
    }
  }
</style>
