<template>
  <div class="enter-code">
    <div v-if="signupText" class="ri-body margin-bottom-xxs">{{ signupText }}</div>
    <div class="ri-large-headline ri-alte margin-bottom-xxs">מה הקוד החד פעמי שקיבלת?</div>
    <div class="ri-large-body margin-bottom-l">שלחנו לך קוד בוואטסאפ למספר <div>{{formattedPhone}}</div></div>

    <text-input
      class="margin-top-l"
      :autofocus="true"
      label-text="קוד 6 ספרות"
      :value="code"
      :onInput="onInput"
      :error="error"
      :inputPattern="'\\d*'"
      :error-message="errorLabel"
      inputType="tel"
      autocomplete="one-time-code" />

    <riseup-button :title="buttonTitle" @click="() => confirmCodeAction(code)" :loading="loading" :disabled="buttonDisabled" :size="'small'"/>

    <div class="action" v-on:click="sendCodeAgain" v-if="!sendAgainLoading">לא קיבלתי את הקוד, שלחו לי שוב</div>
    <div class="loader" v-else/>

  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import phoneNumberUtils from '@/utils/phoneNumber';
import Segment from '../Segment';

const ERRORS = {
  CODE_EXPIRED: 'CODE_EXPIRED',
  INVALID_CODE: 'INVALID_CODE',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export default {
  name: 'EnterCode',
  components: {
    RiseupButton: BaseUI.V2Components.RiseupButton,
    TextInput: BaseUI.V2Components.TextInput,
  },
  props: {
    signupText: {
      type: String,
      required: false,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
    confirmCodeAction: {
      type: Function,
      required: true,
    },
    regenerateCodeAction: {
      type: Function,
      required: true,
    },
    serverError: {
      type: String,
      required: true,
    },
    resetError: {
      type: Function,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      code: '',
      sendAgainLoading: false,
      triedAutoConfirm: false,
    };
  },
  computed: {
    buttonDisabled() {
      return this.code.length !== 6 || this.loading;
    },
    codeContainsOnlyNumbers() {
      return /^([0-9]*)$/.test(this.code);
    },
    error() {
      return !!this.serverError || !this.codeContainsOnlyNumbers;
    },
    errorLabel() {
      if (this.serverError === ERRORS.CODE_EXPIRED) {
        Segment.trackUserGot('expiredCodeError');
        return 'הקוד פג תוקף';
      } if (this.serverError === ERRORS.INVALID_CODE) {
        Segment.trackUserGot('invalidCodeError', { invalidCode: this.code });
        return 'הקוד שהזנת לא תקין';
      } if (!this.codeContainsOnlyNumbers) {
        return 'הקוד צריך להכיל ספרות בלבד';
      } if (this.serverError === ERRORS.UNKNOWN_ERROR) {
        return 'נראה שקרתה תקלה, נסו שוב בהמשך';
      }
      return null;
    },
    formattedPhone() {
      return phoneNumberUtils.visualizePhoneNumber(this.phoneNumber);
    },
  },
  methods: {
    onInput(value) {
      this.code = value.trim();
      this.resetError();
      if (!this.triedAutoConfirm && this.code.length === 6) {
        this.triedAutoConfirm = true;
        this.confirmCodeAction(this.code);
      }
    },
    async sendCodeAgain() {
      this.sendAgainLoading = true;
      await this.regenerateCodeAction();
      this.sendAgainLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../scss/mixins';

  .enter-code {

    .riseup-button {
      width: 100%;
      margin: 10px 0px;
    }

    .action {
      margin: 20px 0px;
      color: $riseup_blue;
      font-weight: bold;
      cursor: pointer;
      font-size: 16px;
      text-align: center;
    }

    .loader {
      margin: 0 auto;
      width: 34px;
      height: 34px;
      border: 4px solid $riseup_blue;
      border-top: 4px solid $riseup_white;
      border-radius: 50%;
      animation: spin 2s linear infinite;

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    }
  }
</style>
