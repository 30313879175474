<template>
  <div>
    <introduction-for-members v-if="authenticated" :next="next" />
    <introduction-for-not-members v-else :next="next" />
  </div>
</template>

<script>

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import DDLogs from '@/DDLogs';
import GenerateCodeMixin from '@/pages/responsive-pages/non-authenticated/wine-and-dine/signup/steps/GenerateCodeMixin';
import IntroductionForMembers from './IntroductionForMembers';
import IntroductionForNotMembers from './IntroductionForNotMembers';
import Segment from '../../../../../../Segment';

export default {
  name: 'Introduction',
  mixins: [GenerateCodeMixin],
  components: {
    IntroductionForNotMembers,
    IntroductionForMembers,
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
  },
  async created() {
    Segment.trackUserGot('RegistrationEntered');
    Segment.trackUserGotToPixel('RE');
    await this.autoContinueIfDetailsAreProvided();
  },
  computed: {
    ...mapState('signup', ['phoneNumber', 'firstName', 'lastName', 'serverErrors', 'email', 'enableMarketingEmails']),
    ...mapState('session', ['authenticated']),
    ...mapGetters('promotions', ['isPromotionEligible']),
  },
  methods: {
    ...mapActions('signup', ['generateCode']),
    ...mapMutations('signup', ['resetError']),
    async generateCodeAndProceed(firstName, lastName, phoneNumber, email, enableMarketingEmails) {
      await this.generateCodeForSignup(firstName, lastName, phoneNumber, email, enableMarketingEmails);
      this.next();
    },
    async autoContinueIfDetailsAreProvided() {
      const firstNameCookie = this.$cookies.get('riseup.firstName');
      const lastNameCookie = this.$cookies.get('riseup.lastName');
      const phoneNumberCookie = this.$cookies.get('riseup.phoneNumber');
      const emailCookie = this.$cookies.get('riseup.email');
      const termsOfUseApprovalCookie = this.$cookies.get('riseup.termsOfUseApproval');
      const emailConsentCookie = this.$cookies.get('riseup.emailConsent');
      if (firstNameCookie && lastNameCookie && phoneNumberCookie && emailCookie && termsOfUseApprovalCookie === 'true') {
        DDLogs.log('Got signup data from cookies', { firstNameCookie, lastNameCookie, phoneNumberCookie, emailCookie, emailConsentCookie });
        await this.generateCodeAndProceed(firstNameCookie, lastNameCookie, phoneNumberCookie, emailCookie, emailConsentCookie || false);
        this.removeCookies();
      } else if (firstNameCookie || lastNameCookie || phoneNumberCookie || emailCookie || termsOfUseApprovalCookie || emailConsentCookie) {
        DDLogs.log('Got partial signup data from cookies - not skipping entering the details',
          { firstNameCookie, lastNameCookie, phoneNumberCookie, emailCookie, emailConsentCookie });
        this.removeCookies();
      }
    },
    removeCookies() {
      this.$cookies.remove('riseup.firstName', '/', '.riseup.co.il');
      this.$cookies.remove('riseup.lastName', '/', '.riseup.co.il');
      this.$cookies.remove('riseup.phoneNumber', '/', '.riseup.co.il');
      this.$cookies.remove('riseup.email', '/', '.riseup.co.il');
      this.$cookies.remove('riseup.termsOfUseApproval', '/', '.riseup.co.il');
      this.$cookies.remove('riseup.emailConsent', '/', '.riseup.co.il');
    },
    goToLogin() {
      window.location.href = '/sr';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../../scss/mixins';
.expired-promotion {
  @include responsive-padding;
  width: 100%;
}
</style>
