import { client } from '@/http';

export async function resetPasswordInAuth0() {
  const response = await client.put('/api/partial-auth/reset-password-in-auth0');
  return response.data;
}

export async function getAuth0CustomerEmail() {
  const response = await client.get('/api/partial-auth/auth0-customer-email');
  return response.data.email;
}
