<template>
  <two-factor-page-base>
    <div class="reset-password-expired-link">
      <div class="content">
        <img :src="require('@/assets/errors/fallen-flower-pot.png')" class="expire-img">
        <span class="ri-body margin-bottom-xxs">איפוס סיסמה</span>
        <div class="ri-large-headline ri-alte margin-bottom-xxs">אופס, הלינק פג תוקף</div>
        <span class="ri-large-body">כדי להגן על התזרים שלך, הלינק ששלחנו מוגבל בזמן. אם צריך, אפשר לבקש לינק חדש</span>
        <riseup-button class="margin-top-l" @click="next" :title="'שלחו לי מייל לאיפוס סיסמה'"/>
      </div>
      <login-support-footer-positioned/>
    </div>
  </two-factor-page-base>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import router from '@/router';
import Segment from '@/Segment';
import LoginSupportFooterPositioned from '@/base-components/LoginSupportFooterPositioned';
import TwoFactorPageBase from './TwoFactorPageBase';

export default {
  name: 'TwofaResetPasswordExpiredLink',
  async created() {
    Segment.trackUserGot('2faPasswordResetLinkExpired');
  },
  methods: {
    onBack() {
      router.go(-1);
    },
    async next() {
      Segment.trackUserInteraction('RedirectTo2faResetPassword');
      window.location.href = '/2fa-Reset-Password';
    },
  },
  components: {
    TwoFactorPageBase,
    LoginSupportFooterPositioned,
    RiseupButton: BaseUI.V2Components.RiseupButton,
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/spacings";

.reset-password-expired-link{
  justify-content: space-between;
  position: relative;

  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    margin: 30px;
    display: flex;
    flex-direction: column;
  }

  .expire-img {
    width: 200px;
    margin-bottom: $ri-spacing-sm;
  }
}
</style>
