<template>
  <two-factor-page-base>
    <div class="reset-password-mail">
      <div class="content">
        <img :src="require('@/assets/celebrating-hands-drawing.png')">
        <span class="ri-body margin-bottom-xxs">איפוס סיסמה</span>
        <div class="ri-large-headline ri-alte margin-bottom-xxs">מצוין, הסיסמה אופסה בהצלחה!</div>
        <span class="ri-large-body">אפשר להמשיך ולהכנס לשירות עם הסיסמה החדשה</span>
        <riseup-button
          class="margin-top-ml"
          @click="next"
          title="כניסה לשירות"/>
      </div>
      <login-support-footer-positioned/>
    </div>
  </two-factor-page-base>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import router from '@/router';
import Segment from '@/Segment';
import LoginSupportFooterPositioned from '@/base-components/LoginSupportFooterPositioned';
import TwoFactorPageBase from './TwoFactorPageBase';

export default {
  name: 'TwofaResetPasswordDone',
  async created() {
    Segment.trackUserGot('2faPasswordResetSuccessfullyEntered');
  },
  methods: {
    onBack() {
      router.go(-1);
    },
    next() {
      Segment.trackUserInteraction('2faResetDoneGoToCashflowClicked');
      window.location.href = `/${this.$route.query.redirectTo || 'sr'}`;
    },
  },
  components: {
    LoginSupportFooterPositioned,
    RiseupButton: BaseUI.V2Components.RiseupButton,
    TwoFactorPageBase,
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/spacings";

.reset-password-mail{
  justify-content: space-between;
  position: relative;

  height: 100%;
  display: flex;
  flex-direction: column;

  .content {
    margin: 30px;
    display: flex;
    flex-direction: column;
  }
  img {
    width: 200px;
    margin-bottom: $ri-spacing-sm;
  }
}
</style>
