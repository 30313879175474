<template>
  <alert-page :img="require('@/assets/generic-faliure.png')" title="נראה שנכנסת דרך לינק לא תקין" :show-support-footer="true">
    <template #content>
      <div>אפשר לבדוק אם הלינק הועתק נכון או לבקש ממי ששלח לינק חדש להצטרפות.</div>
    </template>
  </alert-page>
</template>

<script>
import Segment from '@/Segment';
import AlertPage from '@/base-components/AlertPage';

export default {
  name: 'NoAccessTokenError',
  components: {
    AlertPage,
  },
  async created() {
    Segment.trackUserGot('NoAccessTokenError');
  },
};
</script>
