<template>
  <cookies-disabled-error v-if="cookiesDisabledError" class="error-holder" />
  <invite-member-already-exists-error
    v-else-if="showMemberExistsError"
    class="error-holder"
    :phone-number="phoneNumber"
    :inviter="primaryMemberDetails.firstNamePiiValue" />
  <banner-page  v-else
                class="member-invite-enter-code"
                :background-position="bannerBackgroundPosition"
                :img-src="require('@/assets/banners/blue-shapes-with-logo.svg')"
                :background-color="bannerBackgroundColor">
    <div class="content">
      <enter-code class="enter-code"
                  signup-text="הרשמה"
                  :first-name="firstName"
                  :phone-number="phoneNumber"
                  :confirm-code-action="createMemberAndProceed"
                  :regenerate-code-action="forceGenerateCode"
                  :server-error="errors.generateCode || errors.authenticate"
                  :button-title="'אישור'"
                  :loading="loading"
                  :reset-error="() => resetError('createMember')"/>
    </div>
  </banner-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapMutations, mapActions, mapState, mapGetters } from 'vuex';
import InviteMemberAlreadyExistsError
  from '@/pages/responsive-pages/invite-authenticated/member-invite/pages/InviteMemberAlreadyExistsError';
import CookiesDisabledError from '@/pages/general-errors/CookiesDisabledError.vue';
import Segment from '@/Segment';
import EnterCode from '@/base-components/EnterCode.vue';
import auth0 from '@/utils/auth0';
import CustomersApi from '@/api/CustomersApi';

export default {
  name: 'MemberInviteEnterCode',
  components: {
    InviteMemberAlreadyExistsError,
    EnterCode,
    CookiesDisabledError,
    BannerPage: BaseUI.Pages.BannerPage,
  },
  data() {
    return {
      loading: false,
      bannerBackgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      bannerBackgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
    };
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
  },
  async created() {
    Segment.trackUserGot('MemberInviteEnterCodeEntered');
  },
  computed: {
    ...mapState('memberInvite', ['firstName', 'lastName', 'phoneNumber', 'email', 'errors', 'canAutoChurnExistingCustomer']),
    ...mapGetters('memberInvite', ['inviteMemberFlowSteps', 'errorTypes']),
    ...mapState('inviteSession', ['primaryMemberDetails', 'inviteToken']),
    showMemberExistsError() {
      return (this.errors.createMember === this.errorTypes.memberAlreadyExists
          || this.errors.createMember === this.errorTypes.memberTryingToJoinOwnAccountError)
        && !this.canAutoChurnExistingCustomer;
    },
    cookiesDisabledError() {
      return this.errors.createMember === this.errorTypes.cookiesDisabled;
    },
    memberInviteDetails() {
      return {
        inviterName: this.primaryMemberDetails.firstNamePiiValue,
        inviteToken: this.inviteToken,
        memberInviteDetails: {
          firstName: this.firstName,
          lastName: this.lastName,
          phoneNumber: this.phoneNumber,
          email: this.email,
        },
      };
    },
  },
  methods: {
    ...mapMutations('memberInvite', ['resetError', 'setPhoneNumber']),
    ...mapActions('memberInvite', ['createMember', 'forceGenerateCode', 'authenticate']),
    async createMemberAndProceed(code) {
      try {
        this.loading = true;
        this.setPhoneNumber(this.phoneNumber);
        await this.authenticate(code);
        await this.createMember();
        this.next();
      } catch (e) {
        if (e.response.data === this.errorTypes.memberAlreadyExists) {
          this.$cookies.set('memberInviteDetails', this.memberInviteDetails, '1h');
          const member = await CustomersApi.getPartiallyAuthenticatedMember();
          if (member && !member.hasOidc) {
            Segment.trackUserGot('redirectTo2FaMigrationFromMemberInvite');
            this.$router.push({ path: '/2fa-migration' });
            return;
          }
          Segment.trackUserGot('ExistingCustomerTryingToJoinAnotherAccount_2faRedirect');
          const encodedRedirectTo = auth0.getEncodedRedirectTo({ path: '/invite-existing-customer' });
          window.location.href = `/api/partial-auth/login-with-auth0?redirectTo=${encodedRedirectTo}`;
        }
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/scss/mixins';

.error-holder {
  width: 100%;
}

.member-invite-enter-code {
  width: 100%;

  .image-container {
    width: 100%;
  }

  .content {
    margin-top: 30px;
  }

  .enter-code {
    @include responsive-padding;
    width: 100%;
  }
}

</style>
