<template>
  <signup :title="'הרשמה לשירות'"
  :sub-title="'ליצירת החשבון נצטרך כמה פרטים:'"
  :button-title="'הרשמה'"
  :button-action="generateCodeAndProceed"
          :error="serverErrors.generateCode"
          :reset-error="() => resetError('generateCode')">
    <template v-slot:header>
      <riseup-header/>
      <snack-bar class="expired-promotion" v-if="!isPromotionEligible" :variant="'warning'">
        <template v-slot:primary>
          <div class="bold"> תוקף ההטבה הסתיים </div>
        </template>
      </snack-bar>
    </template>
    <template v-slot:information-details>
      <default-information-details/>
    </template>
  </signup>
</template>

<script>

import Signup from '@/base-components/Signup';
import GenerateCodeMixin from '@/pages/responsive-pages/non-authenticated/wine-and-dine/signup/steps/GenerateCodeMixin';
import BaseUI from '@riseupil/base-ui';
import { mapState, mapMutations, mapGetters } from 'vuex';
import Segment from '../../../../../../Segment';
import RiseupHeader from '../../../../../../base-components/app-header/RiseupHeader.vue';
import DefaultInformationDetails from './DefaultInformationDetails';

export default {
  name: 'IntroductionForNotMembers',
  mixins: [GenerateCodeMixin],
  components: {
    RiseupHeader,
    Signup,
    DefaultInformationDetails,
    SnackBar: BaseUI.SnackBar,
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
  },
  async created() {
    Segment.trackUserGot('RegistrationEntered', { userAgentFromNavigator: this.userAgent, isFacebookApp: this.isFacebookApp });
    Segment.trackUserGotToPixel('RE');
  },
  computed: {
    ...mapState('signup', ['phoneNumber', 'firstName', 'lastName', 'serverErrors', 'email', 'enableMarketingEmails']),
    ...mapGetters('promotions', ['isPromotionEligible']),
    userAgent() {
      return navigator.userAgent || navigator.vendor || window.opera;
    },
    isFacebookApp() {
      return (this.userAgent.indexOf('FBAN') > -1) || (this.userAgent.indexOf('FBAV') > -1);
    },
  },
  methods: {
    ...mapMutations('signup', ['resetError']),
    async generateCodeAndProceed(firstName, lastName, phoneNumber, email, enableMarketingEmails) {
      await this.generateCodeForSignup(firstName, lastName, phoneNumber, email, enableMarketingEmails);
      this.next();
    },
    goToLogin() {
      window.location.href = '/sr';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../../scss/mixins';
.expired-promotion {
  @include responsive-padding;
  width: 100%;

  .bold {
    font-weight: bold;
  }
}
</style>
