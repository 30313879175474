<template>
  <alert-page :img="require('@/assets/happy-star-drawing.png')"
              img-size="small"
              title="שמחים שחזרת! מספר הטלפון שלך קיים אצלנו במערכת"
              button-title="קחו אותי לתזרים"
              :button-action="goToCashflow"
              show-button>
    <template #content>כנראה שנרשמת כבר לשירות עם המספר הזה.</template>
  </alert-page>
</template>

<script>
import Segment from '@/Segment';
import router from '@/router';
import AlertPage from '@/base-components/AlertPage';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'SignupMemberAlreadyExistsError',
  components: {
    AlertPage,
  },
  async created() {
    Segment.trackUserGot('SignupMemberAlreadyExistsError', { phoneNumber: this.phoneNumber });
    Segment.trackUserGotToPixel('SignupMemberAlreadyExistsError');
  },
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState('promotions', ['promotion']),
    ...mapGetters('promotions', ['isPromotionEligible']),
  },
  methods: {
    async goToCashflow() {
      await router.push({ path: '/sr', query: { ...this.$route.query, returning: true } });
    },
  },
};
</script>
