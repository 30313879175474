<template>
  <div class="login-enter-code">
    <banner-page :background-position="bannerBackgroundPosition"
                 :img-src="require('@/assets/banners/blue-shapes-with-logo.svg')"
                 :background-color="bannerBackgroundColor">
      <cookies-disabled-error v-if="cookiesDisabledError"/>
      <login-member-doesnt-exist-error v-else-if="memberDoesntExist" :phoneNumber="phoneNumber"/>
      <div class="content" v-else>
        <enter-code :phone-number="phoneNumber"
                    :confirm-code-action="authenticateAndProceed"
                    :regenerate-code-action="forceGenerateCode"
                    :server-error="serverErrors.authenticate"
                    :button-title="'להמשיך'"
                    :loading="loading"
                    signup-text="כניסה"
                    :reset-error="() => resetError('authenticate')"/>
      </div>
    </banner-page>
    <login-support-footer-positioned/>
  </div>
</template>

<script>
import _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import LoginMemberDoesntExistError from '@/pages/responsive-pages/non-authenticated/login/LoginMemberDoesntExistError';
import router from '@/router';
import LoginSupportFooterPositioned from '@/base-components/LoginSupportFooterPositioned';
import utils from '../../../../utils/phoneNumber';
import EnterCode from '../../../../base-components/EnterCode.vue';
import Segment from '../../../../Segment';
import CookiesDisabledError from '../../../general-errors/CookiesDisabledError.vue';
import questions from '../wine-and-dine/lead-qualification/consts/questions';

export default {
  name: 'LoginEnterCode',
  components: {
    LoginSupportFooterPositioned,
    EnterCode,
    CookiesDisabledError,
    LoginMemberDoesntExistError,
    BannerPage: BaseUI.Pages.BannerPage,
  },
  data() {
    return {
      redirectTo: '',
      loading: false,
      memberDoesntExist: false,
      bannerBackgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      bannerBackgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
    };
  },
  created() {
    this.redirectTo = this.$route.query.redirectTo || 'sr';
    if (!this.phoneNumber) {
      if (this.$route.query.phoneNumber) {
        this.setPhoneNumber(utils.formatPhoneNumber(this.$route.query.phoneNumber));
      } else {
        this.redirectToLogin();
      }
    }
    Segment.trackUserGot('LoginEnterCodeEntered');
  },
  computed: {
    ...mapState('login', ['phoneNumber', 'serverErrors']),
    ...mapGetters('login', ['errorTypes']),
    ...mapState('session', ['activeMember']),
    cookiesDisabledError() {
      return this.serverErrors.authenticate === this.errorTypes.cookiesDisabled;
    },
  },
  methods: {
    ...mapMutations('login', ['resetError', 'setPhoneNumber']),
    ...mapActions('session', ['initRiseupAccount']),
    ...mapActions('login', ['authenticate', 'forceGenerateCode', 'addCashflowForRiseupAccount']),
    redirectToOldState() {
      const carryParams = _.omit(this.$route.query, ['redirectTo', 'phoneNumber']);
      router.push({
        path: `/${this.redirectTo}`,
        query: carryParams,
      });
    },
    redirectToLogin() {
      router.push({
        path: '/login',
        query: this.$route.query,
      });
    },
    redirectToWineAndDine() {
      router.push({
        path: `/signup/lq/question/${questions.FIRST_QUESTION_NAME}`,
        query: this.$route.query,
      });
    },
    async authenticateAndProceed(code) {
      Segment.trackUserInteraction('LoginCodeEnteredClicked');
      try {
        this.loading = true;
        const {
          customerId,
          riseupAccountId,
        } = await this.authenticate(code);
        if (customerId) {
          this.redirectToOldState();
        } else if (riseupAccountId) {
          await this.initRiseupAccount(this.phoneNumber);
          // We already have a riseup account but no customer
          //  for cashflow, so we add a customer.
          const customer = await this.addCashflowForRiseupAccount(this.activeMember);
          Segment.identifyCustomer(customer.customerId);
          Segment.trackUserGot('LoginCustomerCreated',
            {
              customerId: customer.customerId,
              utmParams: customer.intake.utmParams,
              icrc: customer.intake.icrc,
            });
          window.location.href = '/api/partial-auth/login-with-auth0/new';
        } else {
          this.memberDoesntExist = true;
        }
      } catch (e) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-enter-code {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  .image-container {
    width: 100%;
  }

  .content {
    margin: 30px;
  }
}
</style>
