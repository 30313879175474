<template>
  <banner-page :background-position="bannerBackgroundPosition"
               :img-src="require('@/assets/banners/blue-shapes-with-logo.svg')"
               :background-color="bannerBackgroundColor">
    <div class="login-flow">
      <div class="content">
        <enter-phone-number :next="redirectToEnterCode"/>
      </div>
      <login-support-footer-positioned/>
    </div>
  </banner-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import LoginSupportFooterPositioned from '@/base-components/LoginSupportFooterPositioned';
import Segment from '../../../../Segment';
import router from '../../../../router';
import EnterPhoneNumber from './steps/EnterPhoneNumber.vue';

export default {
  name: 'LoginEnterPhoneNumber',
  components: {
    LoginSupportFooterPositioned,
    EnterPhoneNumber,
    BannerPage: BaseUI.Pages.BannerPage,
  },
  data() {
    return {
      bannerBackgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      bannerBackgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
    };
  },
  created() {
    Segment.trackUserGot('LoginEntered');
  },
  methods: {
    async redirectToEnterCode(phoneNumber) {
      const queryParams = { ...this.$route.query, phoneNumber };
      await router.push({ path: '/login-enter-code', query: queryParams });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-flow {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .image-container {
    width: 100%;
  }

  .content {
    margin: 30px;
  }
}
</style>
