<template>
  <div class="login-enter-phone-number">
    <div class="ri-body margin-bottom-xxs">כניסה</div>
    <div class="ri-large-headline ri-alte margin-bottom-xxs">היי, טוב לראות אותך!</div>
    <div class="content">
      <div class="ask-details ri-large-body">
        <span>כדי להיכנס לשירות יישלח לך</span>
        <span class="bold">קוד אימות חד-פעמי בוואטסאפ.</span>
      </div>
      <text-input
        :autofocus="false"
        label-text="טלפון נייד"
        :value="phoneNumber"
        :onInput="onEnter"
        inputType="tel"
        :inputPattern="'\\d*'"
        autocomplete="mobile tel"
        :error="!!serverErrors.generateCode"
        :error-message="phoneNumberErrorLabel"/>
    </div>
    <riseup-button :disabled="disabled" title="לקבלת קוד בוואטסאפ" @click="triggerAction" :loading="loading"/>
    <div class="join">עוד לא נרשמת לשירות? <span class="clickable" v-on:click="join">כאן נרשמים</span></div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapMutations, mapState, mapActions } from 'vuex';
import BaseUI from '@riseupil/base-ui';
import Segment from '../../../../../Segment';
import utils from '../../../../../utils/phoneNumber';

const ERRORS = {
  INVALID_PHONE_NUMBER: 'INVALID_PHONE_NUMBER',
  CUSTOMER_DOESNT_EXISTS: 'CUSTOMER_DOESNT_EXISTS',
};
const WINE_AND_DINE_URL = 'https://signup.riseup.co.il/flow/start';

export default {
  name: 'EnterPhoneNumber',
  components: {
    TextInput: BaseUI.V2Components.TextInput,
    RiseupButton: BaseUI.V2Components.RiseupButton,
  },
  data() {
    return {
      phoneNumber: '',
      loading: false,
    };
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
  },
  async created() {
    Segment.trackUserGot('LoginEnterePhoneNumberEntered');
    const phoneNumber = utils.formatPhoneNumber(this.$router.currentRoute.query.phoneNumber);
    if (phoneNumber) {
      this.onEnter(phoneNumber);
      await this.triggerAction();
    }
  },
  computed: {
    ...mapState('login', ['serverErrors']),
    phoneNumberErrorLabel() {
      if (this.serverErrors.generateCode === ERRORS.INVALID_PHONE_NUMBER) {
        Segment.trackUserGot('invalidPhoneNumber', { invalidPhoneNumber: this.phoneNumber });
        return 'מספר הטלפון שהזנת לא תקין';
      } if (this.serverErrors.generateCode === ERRORS.CUSTOMER_DOESNT_EXISTS) {
        Segment.trackUserGot('Login_EnterPhoneNumber_customerDoesntExistError', { invalidPhoneNumber: this.phoneNumber });
        return 'מספר הטלפון לא רשום במערכת';
      } if (this.serverErrors.generateCode) {
        return 'נראה שקרתה תקלה, נסו שוב בהמשך';
      }
      return null;
    },
    disabled() {
      return _.isEmpty(_.trim(this.phoneNumber));
    },
    normalizedPhoneNumber() {
      return this.phoneNumber.replace(/\D/g, '');
    },
  },
  methods: {
    ...mapMutations('login', ['setPhoneNumber', 'resetError']),
    ...mapActions('login', ['generateCode']),
    onEnter(value) {
      this.resetError();
      this.phoneNumber = value;
    },
    async triggerAction() {
      this.loading = true;
      Segment.trackUserInteraction('GenerateCodeClicked', {
        phoneNumber: this.phoneNumber,
      });
      try {
        await this.setPhoneNumber(this.normalizedPhoneNumber);
        await this.generateCode();
        await this.next(this.normalizedPhoneNumber);
      } finally {
        this.loading = false;
      }
    },
    join() {
      window.location.href = WINE_AND_DINE_URL;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';

  .login-enter-phone-number {
    .title {
      padding: 18px 0px;
      font-weight: bold;
      font-size: 28px;
      line-height: 28px;
    }

    .content {
      .ask-details {
        margin-bottom: 42px;
      }

      .bold {
        font-weight: bold;
      }
    }

    .riseup-button {
      width: 100%;
      margin: 10px 0px;
    }

    .clickable {
      color: $riseup_blue;
      font-weight: bold;
      cursor: pointer;
      text-decoration: underline;
    }

    .join {
      margin: 30px 0px;
      font-size: 16px;
      text-align: center;
    }
  }

</style>
