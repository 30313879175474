<template>
  <two-factor-page-base>
    <div class="reset-password-mail">
      <div class="content">
        <img :src="require('@/assets/snapping-fingers-drawing.png')">
        <div class="ri-body margin-bottom-xxs">איפוס סיסמה</div>
        <div class="ri-large-headline ri-alte margin-bottom-xxs">שלחנו לך מייל, נמשיך את התהליך שם</div>
        <div class="ri-large-body">אפשר לבדוק את המייל, מחכה לך לינק להמשך תהליך איפוס הסיסמה</div>
      </div>
      <login-support-footer-positioned/>
    </div>
  </two-factor-page-base>
</template>

<script>

import router from '@/router';
import Segment from '@/Segment';
import LoginSupportFooterPositioned from '@/base-components/LoginSupportFooterPositioned';
import TwoFactorPageBase from './TwoFactorPageBase';

export default {
  name: 'TwofaResetPassword',
  async created() {
    Segment.trackUserGot('2faPasswordResetEmailSent');
  },
  methods: {
    onBack() {
      router.go(-1);
    },
  },
  components: {
    TwoFactorPageBase,
    LoginSupportFooterPositioned,
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/spacings";

.reset-password-mail {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content {
  margin: 30px;
}
img {
  height: 130px;
  margin-bottom: $ri-spacing-sm;
}
</style>
