<template>
  <banner-page class="full-height"
               :background-position="bannerBackgroundPosition"
               :img-src="require('@/assets/banners/blue-shapes-with-logo.svg')"
               :background-color="bannerBackgroundColor">

    <div class="content">
      <img :src="require('@/assets/celebrating-hands-drawing.png')">
      <div class="ri-large-headline ri-alte margin-bottom-xxs">מעולה,
        עשית את הצעד הראשון שלך
        בדרך לצמיחה כלכלית!
      </div>
      <div class="ri-large-body">{{ firstName }},
        אנחנו שמחים שהצטרפת לרייזאפ! הבוט שלנו מחכה לך בוואטסאפ.
        <span class="ri-large-bold-body">
          נתראה שם!
        </span>
      </div>
      <riseup-button class="margin-top-l" @click="onClick" title="להתחברות לתזרים המשותף" />
    </div>
    <support-footer class="flex-column align-center margin-bottom-sm"/>
  </banner-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapState } from 'vuex';
import router from '@/router';
import Segment from '../../../../../Segment';

export default {
  name: 'MemberInviteDone',
  components: {
    SupportFooter: BaseUI.SupportFooter,
    BannerPage: BaseUI.Pages.BannerPage,
    RiseupButton: BaseUI.V2Components.RiseupButton,
  },
  data() {
    return {
      gotAnswer: false,
      phoneNumber: '',
      code: '',
      bannerBackgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
      bannerBackgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
    };
  },
  async created() {
    Segment.trackUserGot('MemberInviteDoneEntered');
  },
  computed: {
    ...mapState('memberInvite', ['firstName']),
  },
  methods: {
    onClick() {
      Segment.trackUserGot('MemberInviteDone_GoToCashflow_Clicked');
      router.push({ path: '/sr' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../scss/mixins';

.content {
  margin: 30px;
  flex: 1;
  img {
    max-width: 200px;
  }
}
</style>
