<template>
  <banner-page :background-position="bannerBackgroundPosition"
               :img-src="require('@/assets/banners/blue-shapes-with-logo.svg')"
               :background-color="bannerBackgroundColor">
    <slot name="header"/>
    <div class="intake-container">
      <div class="title">{{ title }}</div>
      <div class="subTitle">{{ subTitle }}</div>
      <enter-details :action="buttonAction"
                     :error="error"
                     :reset-error="resetError"
                     :button-title="buttonTitle"
                     :predefined-details="predefinedDetails"/>
      <div class="action" v-on:click="goToLogin">
        כבר הצטרפת לשירות?
        <div class="action-link"> הכניסה מכאן</div>
      </div>
    </div>
    <slot name="information-details"/>
    <div class="trust">
      <div class="icons">
        <img class="trust-icon" :src="require('@/assets/introduction/emun.png')">
      </div>
      <div class="trust-text">
        אנחנו עושים הכל כדי להגן על המידע האישי שלך ורשומים כמאגר מידע רשמי בפיקוח הרשות להגנת הפרטיות של משרד המשפטים
      </div>
    </div>
    <support-footer/>
  </banner-page>
</template>

<script>

import BaseUI from '@riseupil/base-ui';
import EnterDetails from '@/base-components/EnterDetails';
import router from '@/router';

export default {
  name: 'Signup',
  components: {
    EnterDetails,
    SupportFooter: BaseUI.SupportFooter,
    BannerPage: BaseUI.Pages.BannerPage,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    buttonAction: {
      type: Function,
      required: true,
    },
    error: {
      type: String,
      required: true,
    },
    resetError: {
      type: Function,
      required: true,
    },
    predefinedDetails: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      bannerBackgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      bannerBackgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
    };
  },
  methods: {
    goToLogin() {
      router.push({ path: '/sr', query: { ...this.$route.query, returning: true } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../scss/mixins';

.intake-container {
  color: $riseup_black;
  @include responsive-padding;
  margin-top: 30px;

  .generate-code-button {
    width: 100%;
  }

  .title {
    font-family: SimplerPro;
    font-weight: bold;
    font-size: 22px;
    line-height: 1.4;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }

  .subTitle {
    font-size: 18px;
    color: $riseup_black;
    line-height: 1.5em;
    margin-bottom: 24px;

    .wa-verification {
      font-weight: bold;
    }
  }

  .action {
    display: flex;
    justify-content: center;
    margin: 40px 0px;
    color: $riseup_black;
    font-size: 18px;
    line-height: 10px;
    .action-link {
      font-weight: bold;
      color: $riseup_blue;
      cursor: pointer;
      text-decoration: underline;
      margin-right: 5px;
    }
  }
}
.trust {
  @include responsive-padding;
  margin-top: 20px;
  padding: 20px;

  .icons {
    display: flex;
    justify-content: space-evenly;

    .trust-icon {
      height: 100px;
    }
  }

  .trust-text {
    text-align: center;
    font-size: 18px;
    color: $riseup_black;
    line-height: 26px;
    padding-top: 28px;

    .bold {
      font-weight: bold;
    }
  }
}
.support-footer {
  text-align: center;
  padding: 24px 0px;

  .support {
    color: $riseup_black
  }
}

</style>
