<template>
  <div class="member-invite-flow full-height">
    <loader v-if="isLoading"/>
    <template v-else>
      <div v-if="noInviteToken">
        <riseup-header/>
        <no-access-token-error/>
      </div>
      <template v-else>
        <member-invite-enter-details v-if="this.currentFlowStep === this.inviteMemberFlowSteps.enterDetails"
                                     :next="() => setCurrentFlowStep(inviteMemberFlowSteps.enterCode)"/>
        <member-invite-enter-code v-if="this.currentFlowStep === this.inviteMemberFlowSteps.enterCode"
                                  :next="() => setCurrentFlowStep(inviteMemberFlowSteps.done)"/>
        <member-invite-done v-if="this.currentFlowStep === this.inviteMemberFlowSteps.done" />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import NoAccessTokenError from '@/pages/responsive-pages/invite-authenticated/member-invite/NoAccessTokenError';
import RiseupHeader from '@/base-components/app-header/RiseupHeader';
import Loader from '@/base-components/Loader';
import MemberInviteEnterDetails from './pages/MemberInviteEnterDetails.vue';
import MemberInviteEnterCode from './pages/MemberInviteEnterCode.vue';
import MemberInviteDone from './pages/MemberInviteDone.vue';

export default {
  name: 'MemberInviteFlow',
  components: {
    Loader,
    RiseupHeader,
    NoAccessTokenError,
    MemberInviteEnterDetails,
    MemberInviteEnterCode,
    MemberInviteDone,
  },
  computed: {
    ...mapState('memberInvite', ['currentFlowStep']),
    ...mapState('inviteSession', ['isLoading']),
    ...mapGetters('memberInvite', ['inviteMemberFlowSteps']),
    ...mapGetters('inviteSession', ['noInviteToken']),
  },
  methods: {
    ...mapMutations('memberInvite', ['setCurrentFlowStep']),
  },
};
</script>
