<template>
  <two-factor-page-base v-if="isReady">
    <div class="wrong-2fa-account-error-screen">
      <div class="content">
        <img :src="require('@/assets/errors/fallen-flower-pot.png')" class="error-img">
        <span class="ri-large-headline ri-alte margin-bottom-xxs">
          זה לא {{loginMethod.title}} שמופיע אצלנו במערכת
        </span>
        <span class="ri-large-body">
          צריך לנסות להתחבר שוב עם {{loginMethod.text}} הנכון:
          <span class="ri-large-bold-body">{{currentMember.oidc.emailPiiValue}}</span>
        </span>
        <riseup-button class="margin-top-ml" @click="tryAgain" title="לנסות שוב"/>
      </div>
      <login-support-footer class="footer-2fa"/>
    </div>
  </two-factor-page-base>
  <loading-popup v-else title="עוד רגע נמשיך..." />
</template>

<script>

import DDLogs from '@/DDLogs';
import LoadingPopup from '@/base-components/onboarding/LoadingPopup';
import router from '@/router';
import BaseUI from '@riseupil/base-ui';
import Segment from '../../../../Segment';
import CustomersApi from '../../../../api/CustomersApi';
import TwoFactorPageBase from './TwoFactorPageBase';

export default {
  name: 'Wrong2faAccountErrorScreen',
  components: {
    LoadingPopup,
    RiseupButton: BaseUI.V2Components.RiseupButton,
    TwoFactorPageBase,
    LoginSupportFooter: BaseUI.LoginSupportFooter,
  },
  data() {
    return {
      currentMember: {},
      isReady: false,
      bannerBackgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      bannerBackgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
    };
  },
  async created() {
    this.currentMember = await CustomersApi.getPartiallyAuthenticatedMember();
    this.isReady = true;
    Segment.trackUserGot('Wrong2faAccountErrorScreenEntered');
    DDLogs.log('Member used another 2fa account than the one she use on signup');
  },
  computed: {
    loginMethod() {
      switch (this.currentMember.loginMethod) {
      case 'google-oauth2':
        return {
          title: 'חשבון ה־Google',
          text: 'החשבון',
        };
      case 'facebook':
        return {
          title: 'חשבון ה־Facebook',
          text: 'החשבון',
        };
      case 'auth0':
        return {
          title: 'האימייל',
          text: 'האימייל',
        };
      default:
        DDLogs.error('Unknown login method, please add', { loginMethod: this.currentMember.loginMethod });
        return {
          title: 'החשבון',
          text: 'החשבון',
        };
      }
    },
  },
  methods: {
    tryAgain() {
      Segment.trackUserInteraction('Wrong2faAccountError_tryAgainClicked');
      DDLogs.log('Wrong 2fa Account Error - Member clicked on tryAgain');
      router.push({ path: '/sr' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '../../../../scss/mixins';

.wrong-2fa-account-error-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 30px;
    .error-img {
      width: 200px;
      margin-bottom: $ri-spacing-sm;
    }
  }
  .footer-2fa {
    width: 100%;
    bottom: 0;
    padding: 0 30px 30px 30px;
    margin-top: 0;
  }
}
</style>
