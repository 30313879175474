<template>
  <div class="cookies-disabled-error-page">
    <alert-page :img="require('@/assets/red-eight-pointed-alert-flower.svg')"
                img-style="small"
                :title="'כדי להכנס לשירות צריך לאפשר ״עוגיות״ בדפדפן'"
                :showButton="false">
      <template v-slot:content>
        הדפדפן שלך לא מאפשר "עוגיות" ("Cookies"), אנחנו משתמשים בהן כדי לזהות אותך בכניסה לשירות.
         <div class="bold">
          ניתן לאשר אותן בהגדרות הדפדפן או לנסות להכנס באמצעות דפדפן אחר.
         </div>
      </template>
    </alert-page>
  </div>
</template>

<script>
import Segment from '../../Segment';
import AlertPage from '../../base-components/AlertPage.vue';

export default {
  name: 'CookiesDisabledError',
  components: {
    AlertPage,
  },
  async created() {
    window.scrollTo(0, 0);
    Segment.trackUserGot('CookiesDisabledErrorEntered');
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../../scss/mixins';

  .bold {
    margin-top: 15px;
  }

  .support-footer {
    text-align: center;
  }
</style>
