<template>
  <information-details :title="'החודש הראשון ב-riseup בחינם!'"
                       :details="['משלמים רק מהחודש ה-2 לשימוש, ₪45 לחודש',
                      'בלי התחייבות, אפשר לבטל בכל רגע',
                      'לא צריך להכניס פרטי אשראי עד לסוף תקופת הניסיון']"/>
</template>

<script>
import InformationDetails from './InformationDetails';

export default {
  name: 'DefaultInformationDetails',
  components: {
    InformationDetails,
  },
};
</script>
