<template>
  <div class="signup-flow" v-if="currentFlowStep === signupFlowSteps.enterDetails">
    <introduction-with-percentage-discount-promo v-if="isPercentagePromo" :next="goToNextStep"/>
    <introduction-with-promo v-else-if="promotion && isPromotionEligible" :next="goToNextStep"/>
    <introduction v-else :next="goToNextStep"/>
  </div>
  <signup-enter-code v-else-if="currentFlowStep === signupFlowSteps.enterCode"
                     :next="onEnterCode"/>
</template>

<script>

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import IntroductionWithPromo from '@/pages/responsive-pages/non-authenticated/wine-and-dine/signup/steps/IntroductionWithPromo';
import LoginApi from '@/api/LoginApi';
import BenefitsApi from '@/api/BenefitsApi';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';
import IntroductionWithPercentageDiscountPromo
  from '@/pages/responsive-pages/non-authenticated/wine-and-dine/signup/steps/IntroductionWithPercentageDiscountPromo';
import Introduction from './steps/Introduction.vue';
import SignupEnterCode from './steps/SignupEnterCode.vue';
import router from '../../../../../router';

export default {
  name: 'SignupFlow',
  components: {
    Introduction,
    SignupEnterCode,
    IntroductionWithPromo,
    IntroductionWithPercentageDiscountPromo,
  },
  async created() {
    this.setAnswersFromExternalSources(this.$route.query);
    if (this.promoCode) {
      await this.fetchPromotion(this.promoCode);
    }
    if (this.promotion && this.isPromotionExpired) {
      Segment.trackUserGot('ExpiredPromotionUsed', { promotion: this.promotion, promoCode: this.promoCode });
    }
    this.setCurrentFlowStep(this.signupFlowSteps.enterDetails);
    Segment.trackUserGotToPixel('SignUpDetailsEntered');
    Segment.trackUserGot('SignUpDetailsEntered_Marketing');
  },
  props: {
    promoCode: {
      type: String,
    },
  },
  computed: {
    ...mapState('signup', ['currentFlowStep']),
    ...mapState('promotions', ['promotion', 'validatedPromoCode']),
    ...mapGetters('signup', ['signupFlowSteps']),
    ...mapGetters('promotions', ['isPromotionEligible', 'isPercentageDiscountPromo']),
    isPromotionExpired() {
      return this.promotion && !this.isPromotionEligible;
    },
    isPercentagePromo() {
      return this.promotion && this.isPromotionEligible && this.isPercentageDiscountPromo;
    },
  },
  methods: {
    ...mapMutations('signup', ['setCurrentFlowStep']),
    ...mapActions('leadQualification', ['setAnswersFromExternalSources']),
    ...mapActions('promotions', ['fetchPromotion']),
    async onEnterCode() {
      if (this.promotion && this.isPromotionEligible) {
        await BenefitsApi.createBenefitByPromoCode(this.validatedPromoCode);
      }
      const is2faRequired = await LoginApi.is2faRequired();
      if (is2faRequired) {
        Segment.trackUserGot('2faRedirectToAuth0Signup');
        DDLogs.log('Customer redirect to Auth0Signup flow');
        window.location.href = '/api/partial-auth/login-with-auth0/new';
      } else {
        await router.replace({ path: '/sr' });
      }
    },
    goToNextStep() {
      Segment.trackUserInteractionToPixel('SignUpDetailsEnteredAndClicked');
      this.setCurrentFlowStep(this.signupFlowSteps.enterCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.signup-flow {
  height: 100%;
}
</style>
