<template>
  <alert-page :img="require('@/assets/generic-faliure.png')"
              :title="title"
              :show-support-footer="true">
    <template #content>
      <div>
        מספר הטלפון שלך כבר קיים אצלנו בשירות.
      </div>
    </template>
  </alert-page>
</template>

<script>
import Segment from '@/Segment';
import AlertPage from '@/base-components/AlertPage';

export default {
  name: 'InviteMemberAlreadyExistsError',
  components: {
    AlertPage,
  },
  async created() {
    Segment.trackUserGot('InviteMemberAlreadyExistsError', { phoneNumber: this.phoneNumber });
  },
  props: {
    phoneNumber: {
      type: String,
      required: true,
    },
    inviter: {
      type: String,
      required: true,
    },
  },
  computed: {
    title() {
      return `לא הצלחנו לצרף אותך לתזרים של ${this.inviter}`;
    },
  },
};
</script>
