<template>
  <signup :title="title()"
          :sub-title="'ליצירת החשבון נצטרך כמה פרטים:'"
          button-title="הצטרפות לתזרים משותף"
          :button-action="generateCodeAndProceed"
          :error="errors.generateCode"
          :reset-error="() => resetError('generateCode')"
          :predefined-details="predefinedDetails">
  </signup>
</template>

<script>
import Signup from '@/base-components/SignupV2';
import { mapMutations, mapState, mapActions } from 'vuex';
import Intercom from '@/Intercom';
import Segment from '@/Segment';
import consts from '@/store/utilities/constants';
import featureFlag from '@/api/featureFlag';

export default {
  name: 'MemberInviteEnterDetails',
  components: {
    Signup,
  },
  data() {
    return {
      predefinedDetails: null,
    };
  },
  props: {
    next: {
      type: Function,
      required: true,
    },
  },
  async created() {
    if (this.$cookies.get('memberInviteDetails')) {
      this.predefinedDetails = this.$cookies.get('memberInviteDetails')?.memberInviteDetails;
      this.$cookies.remove('memberInviteDetails');
      Segment.trackUserGot('MemberInviteDetailsAutoFilled');
    }
    Segment.trackUserGot('MemberInviteEnterDetailsEntered', { customerId: this.customerId });
  },
  computed: {
    ...mapState('memberInvite', ['errors']),
    ...mapState('inviteSession', ['primaryMemberDetails', 'customerId']),
  },
  methods: {
    ...mapMutations('memberInvite', ['setFirstName', 'setLastName', 'setPhoneNumber', 'resetError', 'setEmail', 'setEnableMarketingEmails']),
    ...mapActions('memberInvite', ['generateCode']),
    ...mapActions('featureFlags', ['isFeatureFlagOn']),
    async generateCodeAndProceed(firstName, lastName, phoneNumber, email, enableMarketingEmails) {
      this.setFirstName(firstName);
      this.setLastName(lastName);
      this.setPhoneNumber(phoneNumber);
      this.setEmail(email);
      this.setEnableMarketingEmails(enableMarketingEmails);
      /* if the customer went through the auto-churn flow, he might have a recent active OTP code.
         in that case we want to force generate a new code to avoid confusion */
      const shouldForceGenerateCode = !!this.predefinedDetails;
      await this.generateCode(shouldForceGenerateCode);
      await Intercom.boot(
        this.customerId, this.primaryMemberDetails.firstNamePiiValue,
        {
          numberOfMembers: 2,
          phone2: phoneNumber,
          firstName2: firstName,
        },
        (await featureFlag.getTreatmentInviteAuth(consts.FEATURE_FLAG_NAMES.CASHFLOW_TABS)) === 'on',
      );
      this.next();
    },
    title() {
      return `הוזמנת למנוי משותף עם ${this.primaryMemberDetails.firstNamePiiValue}, ביחד קל יותר לנצח את החודש!`;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-image {
  width: 100%;
}
</style>
