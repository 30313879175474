<template>
  <two-factor-page-base>
    <div class="reset-password-mail">
      <div class="content">
        <span class="ri-body margin-bottom-xxs">איפוס סיסמה</span>
        <div class="ri-large-headline ri-alte margin-bottom-xxs">אין בעיה, רוצה שנשלח לך לינק לאיפוס סיסמה?</div>
        <span class="ri-large-body">נשלח את הלינק לכתובת שאיתה התחברת</span>
        <text-input class="margin-top-xl" :value="email" disabled label-text="אימייל" />
        <riseup-button
          @click="sendPasswordResetEmail"
          title="שלחו לי לינק לאיפוס סיסמה"/>
      </div>
      <login-support-footer-positioned/>
    </div>
  </two-factor-page-base>
</template>

<script>
import router from '@/router';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import LoginSupportFooterPositioned from '@/base-components/LoginSupportFooterPositioned';
import { resetPasswordInAuth0, getAuth0CustomerEmail } from '@/api/2faApi';
import TwoFactorPageBase from './TwoFactorPageBase';

export default {
  name: 'TwofaResetPassword',
  async created() {
    Segment.trackUserGot('2faPasswordResetEmailPageLoaded');
    this.email = await getAuth0CustomerEmail();
    if (!this.email) {
      await router.push({ path: '/2fa-Reset-Password-Error' });
    }
  },
  data() {
    return {
      email: '',
    };
  },
  methods: {
    onBack() {
      router.go(-1);
    },
    async sendPasswordResetEmail() {
      await resetPasswordInAuth0();
      Segment.trackUserGot('2faPasswordResetEmailRequested');
      await router.push({ path: '/2fa-Reset-Password-Sent' });
    },
  },
  components: {
    TwoFactorPageBase,
    LoginSupportFooterPositioned,
    RiseupButton: BaseUI.V2Components.RiseupButton,
    TextInput: BaseUI.V2Components.TextInput,
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.reset-password-mail {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  justify-content: space-between;
  margin: 30px;
}

.email-span {
  font-size: 14px;
  color: $riseup_gray_2;
  margin-top: 45px;
  margin-bottom: 5px;
}
</style>
