<template>
  <banner-page :background-position="bannerBackgroundPosition"
               :img-src="require('@/assets/banners/blue-shapes-with-logo.svg')"
               :background-color="bannerBackgroundColor">
    <slot />
  </banner-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';

export default {
  name: 'TwoFactorPageBase',
  components: {
    BannerPage: BaseUI.Pages.BannerPage,
  },
  data() {
    return {
      bannerBackgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLUE,
      bannerBackgroundPosition: BaseUI.LayoutConsts.BACKGROUND_POSITION.BOTTOM_LEFT,
    };
  },
};
</script>

<style scoped>

</style>
