<template>
  <div class="information-details">
    <div class="details-title">{{ this.title }}</div>
    <div class="detail" v-for="detail in this.details" :key="detail">
      <div class="img-container">
        <img class="check" :src="require('@/assets/icons/check_mark.svg')"/>
      </div>
      <div class="content">
        {{ detail }}
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'InformationDetails',
  props: {
    title: {
      type: String,
      required: true,
    },
    details: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '../../../../../../scss/mixins';

.information-details {
  background-color: $riseup_beige;
  padding: 60px 30px;

  .details-title {
    font-size: 22px;
    font-weight: bold;
    line-height: 32px;
    text-align: center;
    margin-bottom: 30px;
  }
  .detail {
    font-size: 18px;
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: 40px;
    }
    .img-container {
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      height: 36px;
      width: 36px;
      background-color: $riseup_yellow;
      border-radius: 50%;

      img {
        display: flex;
        align-self: center;
      }

    }

    .content {
      display: flex;
      align-self: center;
      line-height: 26px;
    }
  }
}

</style>
